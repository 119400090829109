import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
} from "reactstrap"
import * as SHelper from "../../helpers/session_helper"

const EmailSideBar = (props) => {
  
  const [page , setPage]=useState('syllabus')

  const goto = (pageName) =>{
    return `/course?page=${pageName}`;
  }

  useEffect(()=>{
    if(props.pageName){
      setPage(props.pageName)
    }
  },[props.pageName])

  return (
    <React.Fragment>
      <Card className="email-leftbar">
        <div className="mail-list mt-1">
          <Link to={goto('syllabus')} className={page=="syllabus"?"active":""}>
            <i className="mdi mdi-star-outline me-2"></i>Syllabus
          </Link>
          <Link to={goto('classes')} className={page=="classes"?"active":""}>
            <i className="mdi mdi-email-check-outline me-2"></i>Classes
          </Link>
          <Link to={goto('files')} className={page=="files"?"active":""}>
            <i className="mdi mdi-email-check-outline me-2"></i>Files
          </Link>
          {SHelper.userHasEditPermission() &&
          <Link to={goto('participants')} className={page=="participants"?"active":""}>
            <i className="mdi mdi-clipboard-account me-2"></i>Participants
          </Link>}
          <Link to={goto('instructors')} className={page=="instructors"?"active":""}>
            <i className="mdi mdi-clipboard-account me-2"></i>Instructors
          </Link>
          {SHelper.userHasEditPermission() &&
          <Link to={goto('attendances')} className={page=="attendances"?"active":""}>
            <i className="mdi mdi-clipboard-account me-2"></i>Attendances
          </Link>}
          
          <Link to={goto('modules')} className={page=="modules"?"active":""}>
            <i className="mdi mdi-email-outline me-2"></i> Modules
            {/* <span className="ms-1 float-end">(18)</span> */}
          </Link>
          <Link to={goto('assignments')} className={page=="assignments"?"active":""}>
            <i className="mdi mdi-diamond-stone me-2"></i>Assignments
          </Link>
          {/* <Link to={goto('quizzes')} className={page=="quizzes"?"active":""}>
            <i className="mdi mdi-file-outline me-2"></i>Quizzes
          </Link> */}
        </div>
      </Card>

    </React.Fragment>
  )
}

export default EmailSideBar