import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    CardImg
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';

import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);

    const [organizationName, setOrganizationName] = useState('');
    const [shortOverviewState, setShortOverviewState] = useState(EditorState.createEmpty());
    const [shortOverviewContent, setShortOverviewContent] = useState();

    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();

    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleShortOverviewChange = (newEditorState) => {
        setShortOverviewState(newEditorState);
        setShortOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleSubmit = async (event, errors, values) => {
        if(errors.length>0){
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if ((props.id && props.id>0 && imageSrc==mediaImage) || (imageSrc==mediaImage && files.length==0)){
            toast.error(`Feature image is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else{
            const jsonData = {
                organizationName: values.organizationName,
                organizationShortOverview: shortOverviewContent,
                organizationOverview: overviewContent
            }
            
            await Axios.post(`/page/aboutOrg`, jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {
                    
                    const formData = new FormData();
                    for (let i = 0; i < files.length; i++) {
                        formData.append('file', files[i]);
                    }
                    if(files.length>0 && updateData.files){
                        const removeFilesName = [];
                        for (let i = 0; i < updateData.files.length; i++) {
                            removeFilesName.push(updateData.files[i].name);
                        }
                        formData.append(`remove`, JSON.stringify(removeFilesName));
                    }
                    await Axios.post(`/file/upload/aboutOrg&${response.data.id}`, formData, {
                    })
                    .then(response => { 
                        toast.success('Organization Info updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
                    .catch(function (error) {
                        toast.error("File Uploaded Failed !", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((e) => {
                toast.error(e, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
            
        }

    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }


    useEffect(async () => {
        window.sLoader()
            await Axios.get(`/page/aboutOrg`)
            .then((response) => { 
            if(response.data.status===200){
                if(response.data.data){
                    setUpdateData(response.data.data);
                    setOrganizationName(response.data.data.organizationName)

                    setShortOverviewContent(response.data.data.organizationShortOverview)
                    const courseShortOverview = EditorState.createWithContent(stateFromHTML(response.data.data.organizationShortOverview));
                    setShortOverviewState(courseShortOverview)
                    
                    setOverviewContent(response.data.data.organizationOverview)
                    const courseOverview = EditorState.createWithContent(stateFromHTML(response.data.data.organizationOverview));
                    setOverviewState(courseOverview)
                    
                    if(response.data.data.files.length>0){
                        setImageSrc(CHelper.getsimplifyLink(response.data.data.files[0].link))
                    }
                }
            }
            })
        
        window.hLoader()
    }, []);


    return (
        <Row>
            <Col xl="12">
                <Card>
                    <CardBody>
                        <AvForm className="needs-validation" onSubmit={handleSubmit}>
                            <Row>
                                <Col md="12">
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3" style={{ textAlign: "center" }}>
                                                {/* <CardTitle className="h4">Feature Image</CardTitle> */}
                                                <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "250px", width: "auto" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3 justify-content-center d-flex flex-row">
                                                <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Change Image<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" multiple="multiple" /></label>
                                                <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="12">
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Name</Label>
                                            <AvField
                                                name="organizationName"
                                                value={organizationName}
                                                onChange={(e)=>{setOrganizationName(e.target.value);}}
                                                type="text"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('organizationOverview')}`}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom05">Short Overview</Label>
                                            <Editor
                                                editorState={shortOverviewState}
                                                onEditorStateChange={handleShortOverviewChange}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom05">Overview</Label>
                                            <Editor
                                                editorState={overviewState}
                                                onEditorStateChange={handleOverviewChange}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                            />
                                        </div>
                                    </Col>


                                </Col>
                            </Row>
                            <Col style={{ textAlign: 'right' }}>
                                <Button color="primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Model
