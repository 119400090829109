import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    CardImg
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";
import * as Session from "../../../helpers/session_helper"
const moment = require('moment');

const Model = (props) => {
    const history = useHistory();
    const [userDetails, setUserDetails] = useState(false);
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if(errors.length>0){
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if (props.id && props.id>0 && imageSrc==mediaImage){
            toast.error(`Feature image is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else{
            const jsonData = {
                userFirstName: values.userFirstName,
                userLastName: values.userLastName,
                userContactNo: values.userContactNo,
                userEmail: values.userEmail,
                userAddress: values.userAddress,
            }
            
            await Axios.patch(`/user/details/id/${props.userDetailsAll.userDetailId}`, jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {
                    
                    const formData = new FormData();
                    for (let i = 0; i < files.length; i++) {
                        formData.append('file', files[i]);
                    }
                    if(files.length>0 && props.userDetailsAll.profilePicture){
                        const removeFilesName = [];
                        for (let i = 0; i < props.userDetailsAll.profilePicture.length; i++) {
                            removeFilesName.push(props.userDetailsAll.profilePicture[i].name);
                        }
                        formData.append(`remove`, JSON.stringify(removeFilesName));
                    }
                    await Axios.post(`/file/upload/profilePicture&${props.userDetailsAll.userDetailId}`, formData, {
                    })
                    .then(response => { 
                        toast.success('User Details updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
                    .catch(function (error) {
                        toast.error("File Uploaded Failed !", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((e) => {
                toast.error(e, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
            
            
        }

        window.hLoader();
    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }


    useEffect(async () => {
        if(props.userDetailsAll){
            if (props.userDetailsAll.profilePicture && props.userDetailsAll.profilePicture.length>0) {
                setImageSrc(CHelper.getsimplifyLink(props.userDetailsAll.profilePicture[0].link))
            }
            setUserDetails(props.userDetailsAll.userDetail)
        }
    }, [props.userDetailsAll]);


    return (
        <Row>
            {userDetails && 
                <Col xl="12">
                    <Card>
                        <CardBody style={{paddingTop:"0"}}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="8">
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>First Name</Label>
                                                <AvField
                                                    name="userFirstName"
                                                    defaultValue={userDetails.userFirstName}
                                                    // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                    type="text"
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('userFirstName')}`}
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Last Name</Label>
                                                <AvField
                                                    name="userLastName"
                                                    defaultValue={userDetails.userLastName}
                                                    // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                    type="text"
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('userLastName')}`}
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Email</Label>
                                                <AvField
                                                    name="userEmail"
                                                    defaultValue={userDetails.userEmail}
                                                    // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                    type="email"
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('userEmail')}`}
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Contact No</Label>
                                                <AvField
                                                    name="userContactNo"
                                                    defaultValue={userDetails.userContactNo}
                                                    // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                    type="text"
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('userContactNo')}`}
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>

                                    </Col>
                                    <Col md="4">
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3" style={{ textAlign: "center" }}>
                                                    {/* <CardTitle className="h4">Feature Image</CardTitle> */}
                                                    <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "300px", width: "auto" }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3 justify-content-center d-flex flex-row">
                                                    <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}>Change<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" multiple="multiple" /></label>
                                                    <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>User Address</Label>
                                            <AvField
                                                name="userAddress"
                                                defaultValue={userDetails.userAddress}
                                                // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                type="text"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('userAddress')}`}
                                                validate={{ required: { value: true } }}
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button color="primary" type="submit">
                                        Update
                                    </Button>
                                </Col>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
