import React, { useState, useEffect } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { toast } from 'react-toastify/dist/react-toastify';

const Model = (props) => {
    const [footerPageDetails, setFooterPageDetails] = useState(false);

    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();

    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleSubmit = async (event, errors, values) => {
        if (!overviewContent.length>0) {
            toast.error(`Privacy Policy is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {

            const jsonData = {
                footerPageDetails: overviewContent
            }

            await Axios.post("/footerPage/privacyPolicy", jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success('Privacy Policy updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })

        }

    }


    useEffect(async () => {
        await Axios.get(`/footerPage/privacyPolicy`)
            .then((response) => {
                if (response.data.status === 200) {
                    setFooterPageDetails(response.data.data);

                    setOverviewContent(response.data.data.footerPageDetails)
                    const overview = EditorState.createWithContent(stateFromHTML(response.data.data.footerPageDetails));
                    setOverviewState(overview)
                }
            })
    }, []);


    return (
        <div className="page-content">
            <Breadcrumbs title="Page" breadcrumbItem="Privacy Policy" />
            <Row>
                {(!props.id || props.id === 0 || (props.id > 0 && footerPageDetails)) &&
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="overviewState">Privacy Policy</Label>
                                                <Editor
                                                    editorState={overviewState}
                                                    onEditorStateChange={handleOverviewChange}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </div>

    )
}

export default Model
