import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Modal, Input,
  TabContent,
  TabPane,
  NavLink,
  CardTitle,
  Nav,
  NavItem, } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Model from "./model"


const DatatableTables = () => {

  
  return (
    <>
      <div className="page-content">

        <Breadcrumbs title="Page" breadcrumbItem="List of Courses" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Model/>
              </CardBody>
            </Card>
          </Col>

          {/* <CustomModal modelShow={update_status_modal_center} handleCallback={modalCallbackStatus}/> */}
          {/* <CustomModal modelShow={reset_modal_center} handleCallback={modalCallbackReset} bodyMsg={"New password will sent to the user through user email address."}/> */}
        </Row>
      </div>

    </>
  )
}

export default DatatableTables
