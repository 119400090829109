import React,{useState,useEffect} from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MainModel from "./model"

const Create = (props) => {

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Page" breadcrumbItem="New Instructor Profile" />
        <MainModel/>
      </div>
    </>
  )
}

export default Create
