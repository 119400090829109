import { Card, CardBody, Row, Progress } from 'reactstrap';
import React, { useEffect, useState } from "react";
import Axios from "../../helpers/axios_helper";
import * as Session from "../../helpers/session_helper"

const MiniWidget = () => {
    const [data, setData]=useState(false)
    const [isParticipant, setIsParticipant]=useState(false)
    

    useEffect(()=>{

        const user=Session.getUser()
        if(user && user.role_code=='444') {
            setIsParticipant(true)
        }

    Axios.get('/dashboard/miniWidgetData')
      .then(function (response) {
        if (response && response.data.length>0) {
            let tempdata=response.data[0];
            setData(tempdata);
        }
      })
      .catch(function (error) {
        // navigate('/');
      });

      
    },[])
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row className="align-items-center">
                        <div className="col-8">
                            <p className="mb-2">Active Course</p>
                            <h4 className="mb-0">{data.totalCourse}</h4>
                        </div>
                        <div className="col-4">
                            <div className="text-end">
                                <div>
                                    <button type="button" className="btn btn-sm btn-success waves-effect waves-light">
                                        Details
                                    </button>
                                </div>
                                <div className="mt-3">
                                    <Progress
                                        value="100"
                                        color="primary"
                                        className="bg-transparent progress-sm"
                                        size="sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Row className="align-items-center">
                        <div className="col-8">
                            <p className="mb-2">Instructor</p>
                            <h4 className="mb-0">{data.totalInstructor}</h4>
                        </div>
                        <div className="col-4">
                            <div className="text-end">
                                <div>
                                    <button type="button" className="btn btn-sm btn-success waves-effect waves-light">
                                        Details
                                    </button>
                                </div>
                                <div className="mt-3">
                                    <Progress
                                        value="100"
                                        color="warning"
                                        className="bg-transparent progress-sm"
                                        size="sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardBody>
            </Card>
            {!isParticipant && 
            <Card>
                <CardBody>
                    <Row className="align-items-center">
                        <div className="col-8">
                            <p className="mb-2">Student</p>
                            <h4 className="mb-0">{data.totalStudents}</h4>
                        </div>
                        <div className="col-4">
                            <div className="text-end">
                                <div>
                                    <button type="button" className="btn btn-sm btn-success waves-effect waves-light">
                                        Details
                                    </button>
                                </div>
                                <div className="mt-3">
                                    <Progress
                                        value="100"
                                        color="success"
                                        className="bg-transparent progress-sm"
                                        size="sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardBody>
            </Card>
            }
        </React.Fragment>
    )
}

export default MiniWidget;