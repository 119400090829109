import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Slider from "../pages/Administrator/HomePage/slider"
import WhyChooseUs from "../pages/Administrator/HomePage/whyChooseUs"
import AboutOrganization from "../pages/Administrator/AboutOrganization"

// Course
import CourseTypeIndex from "../pages/Administrator/CourseType/index"
import CourseTypeCreate from "../pages/Administrator/CourseType/create"
import CourseIndex from "../pages/Course/index"
import CourseCreate from "../pages/Course/create"

import Course from "../pages/CourseCurriculam/courseDetailModel"

// Zoom
import ZoomIndex from "../pages/Zoom/index"
import ZoomIndex2 from "../pages/Zoom/redirect"
import WebView from "../pages/Zoom/webView"
import ZoomMeetingRoom from "../pages/Zoom/meetingRoom"

// Event
import EventTypeIndex from "../pages/Administrator/EventType/index"
import EventTypeCreate from "../pages/Administrator/EventType/create"
import EventIndex from "../pages/Administrator/Event/index"
import EventCreate from "../pages/Administrator/Event/create"

// AcademicProfile
import AcademicProfileIndex from "../pages/AcademicProfile/index"
import AcademicProfileCreate from "../pages/AcademicProfile/create"
import ParticipantCheck from "../pages/AcademicProfile/Participant"

// InstructorProfile
import InstructorProfileIndex from "../pages/InstructorProfile/index"
import InstructorProfileCreate from "../pages/InstructorProfile/create"

// FooterPage
import ContactUSPage from "../pages/Administrator/FooterPages/contactUS"
import FAQPage from "../pages/Administrator/FooterPages/faq"
import PrivacyPolicyPage from "../pages/Administrator/FooterPages/privacyPolicy"
import TermAndConditionPage from "../pages/Administrator/FooterPages/termsAndCondition"

// News & Blog
import NewsIndex from "../pages/Administrator/News/index"
import NewsCreate from "../pages/Administrator/News/create"
import BlogIndex from "../pages/Administrator/Blog/index"
import BlogCreate from "../pages/Administrator/Blog/create"

// Page
import PageIndex from "../pages/Administrator/Page/index"
import PageCreate from "../pages/Administrator/Page/create"

// Web Message
import WebMessageIndex from "../pages/Administrator/WebMessage/index"
import WebMessageCreate from "../pages/Administrator/WebMessage/create"

// Notification
import NotificationIndex from "../pages/Administrator/Notification/index"
import NotificationCreate from "../pages/Administrator/Notification/create"

// ErrorPage
import Page200 from "../pages/ErrorPage/page-200"
import Page404 from "../pages/ErrorPage/page-404"
import Page500 from "../pages/ErrorPage/page-500"

// Account
import ChartOfAccountIndex from "../pages/ChartOfAccount/index"
import ChartOfAccountCreate from "../pages/ChartOfAccount/create"
import TransactionIndex from "../pages/Transaction/index"
import TransactionCreate from "../pages/Transaction/create"

// Cheque
import BankAccountIndex from "../pages/BankAccount/index"
import BankAccountCreate from "../pages/BankAccount/create"
import ChequeIndex from "../pages/Cheque/index"
import ChequeCreate from "../pages/Cheque/create"

// Report
import BalanceSheet from "../pages/Report/balanceStatement"
import IncomeStatement from "../pages/Report/incomeStatement"
import CustomReport from "../pages/Report/filterReport"
import ChequeReport from "../pages/Report/chequeReport"

// User
import UserIndex from "../pages/User/index"
import UserCreate from "../pages/User/create"

// Profile
import UserProfile from "../pages/Authentication/UserProfile/index"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"


const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/home-page-slider", component: Slider },
  { path: "/home-page-why-choose-us", component: WhyChooseUs },
  { path: "/about-organization", component: AboutOrganization },

  //Page
  { path: "/page-create", component: PageCreate },
  { path: "/page-list", component: PageIndex },

  //Page
  { path: "/web-message-create", component: WebMessageCreate },
  { path: "/web-message-list", component: WebMessageIndex },

  //Page
  { path: "/notification-create", component: NotificationCreate },
  { path: "/notification-list", component: NotificationIndex },

  //Course
  { path: "/course-type-create", component: CourseTypeCreate },
  { path: "/course-type-list", component: CourseTypeIndex },
  { path: "/course-create", component: CourseCreate },
  { path: "/course-list", component: CourseIndex },

  { path: "/course", component: Course },

  //Zoom
  { path: "/join-meeting-1", component: ZoomIndex },
  { path: "/join-meeting-2", component: ZoomIndex2 },
  // { path: "/join-meeting-3", component: WebView },
  { path: "/meeting-room", component: ZoomMeetingRoom },

  //AcademicProfile
  { path: "/academic-profile-create", component: AcademicProfileCreate },
  { path: "/academic-profile-list", component: AcademicProfileIndex },

  //InstructorProfile
  { path: "/instructor-profile-create", component: InstructorProfileCreate },
  { path: "/instructor-profile-list", component: InstructorProfileIndex },

  //FooterPage
  { path: "/contact-us", component: ContactUSPage },
  { path: "/faq", component: FAQPage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/terms-and-condition", component: TermAndConditionPage },

  //Event
  { path: "/event-type-create", component: EventTypeCreate },
  { path: "/event-type-list", component: EventTypeIndex },
  { path: "/event-create", component: EventCreate },
  { path: "/event-list", component: EventIndex },

  //NewsBlog
  { path: "/news-create", component: NewsCreate },
  { path: "/news-list", component: NewsIndex },
  { path: "/blog-create", component: BlogCreate },
  { path: "/blog-list", component: BlogIndex },
  
  //Account
  { path: "/chart-of-account", component: ChartOfAccountCreate },
  { path: "/chart-of-account-list", component: ChartOfAccountIndex },
  { path: "/transaction", component: TransactionCreate },
  { path: "/transaction-list", component: TransactionIndex },

  //Cheque
  { path: "/bank-account", component: BankAccountCreate },
  { path: "/bank-account-list", component: BankAccountIndex },
  { path: "/cheque", component: ChequeCreate },
  { path: "/cheque-list", component: ChequeIndex },

  //Report
  { path: "/balance-sheet", component: BalanceSheet },
  { path: "/income-statement", component: IncomeStatement },
  { path: "/custom-report", component: CustomReport },
  { path: "/cheque-report", component: ChequeReport },

    
  //User
  { path: "/user", component: UserCreate },
  { path: "/user-list", component: UserIndex },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/user-profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/join-meeting", component: WebView },
  
  // AcademicProfile
  { path: "/new-enroll", component: () => <Redirect to="/participant-registration?status=new" /> },
  { path: "/participant-registration", component: AcademicProfileCreate },
  { path: "/participant", component: ParticipantCheck },
  
  { path: "/page-200", component: Page200 },
  { path: "/page-404", component: Page404 },
  { path: "/page-500", component: Page500 }
]

export { userRoutes, authRoutes }