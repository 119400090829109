import React, { useState } from 'react';
import {
  Label,
  Card,
  CardBody,
  Button,
  Col,
  Row
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import * as custom_helper from '../../helpers/custom_helper'

function Model() {
  const [roomNumber, setRoomNumber] = useState('');
  const [roomPass, setRoomPass] = useState('');

  const client = ZoomMtgEmbedded.createClient();

  var authEndpoint = `${custom_helper.baseUrl()}/open/zoom/signature`
  var sdkKey = 'iFtSrW6QjqLRQnhgEglQ'
  var role = 0
  var userName = 'React'
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''

  function getSignature(e) {
    e.preventDefault();

    fetch(authEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: roomNumber,
        role: role
      })
    }).then(res => res.json())
      .then(response => {
        startMeeting(response.signature)
      }).catch(error => {
        console.error(error)
      })
  }

  function startMeeting(signature) {

    let meetingSDKElement = document.getElementById('meetingSDKElement');
    let meetingSDKChatElement = document.getElementById('meetingSDKChatElement')

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: 'en-US',
      customize: {
        meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
        // toolbar: {
        //   buttons: [
        //     {
        //       text: 'Custom Button',
        //       className: 'CustomButton',
        //       onClick: () => {
        //         console.log('custom button');
        //       }
        //     }
        //   ]
        // },
        video: {
          isResizable: true,
          isResponsive: true,
          // popper: {
          //   disableDraggable: true
          // },
          viewSizes: {
            default: {
              width: 880,
              height: 500,
              // maxWidth: 900,
              // maxHeight: 500
            },
            ribbon: {
              width: 300,
              height: 700
            }
          }
        },
        // chat: {
        //   popper: {
        //     // disableDraggable: true,
        //     anchorElement: meetingSDKChatElement,
        //     placement: 'top'
        //   }
        // }
      }
    });

    client.join({
      signature: signature,
      sdkKey: sdkKey,
      meetingNumber: roomNumber,
      password: roomPass,
      userName: userName,
      userEmail: userEmail,
      tk: registrantToken,
      zak: zakToken
    })

  }

  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                <AvForm>
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="academicFirstName">Meeting Room Number</Label>
                        <AvField
                          name="academicFirstName"
                          value={roomNumber}
                          type="text"
                          className="form-control"
                          id="academicFirstName"
                          onChange={(e) => setRoomNumber(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="academicMiddleName">Meeting Room Pass</Label>
                        <AvField
                          name="academicFirstName"
                          value={roomPass}
                          type="text"
                          className="form-control"
                          id="academicFirstName"
                          onChange={(e) => setRoomPass(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col>
                      <Button color="primary" type="button" onClick={getSignature}>
                        Join Meeting
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
            <br/>
            <br/>
            <Row>
            <Col md={12} id="meetingSDKElement">

            </Col>
            {/* <Col md={3} id="meetingSDKChatElement">

            </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default Model;
