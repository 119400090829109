import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import * as SHelper from '../../helpers/session_helper'

function Redirect() {
    const history = useHistory();

    useEffect(() => {

      const values={
        roomNumber:"83730438367",
        roomPass:"ZL2cu4",
        authEndpoint:'http://braintechsolution.com:3334/api/open/zoom/signature',
        sdkKey:'iFtSrW6QjqLRQnhgEglQ',
        role:0,
        userName:"Asiqur Rahman",
        userEmail:"",
        registrantToken:"",
        zakToken:"",
        leaveUrl:window.location.href,
      };
      let encryptedUrl= SHelper.encrypt(JSON.stringify(values));
      let url=`http://localhost:3001/${encodeURIComponent(encryptedUrl)}`;
      window.open(url, '_blank');
      history.push('/')
    }, [])

    return (<></>);
}

export default Redirect;
