import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    CardImg,
    Table,
} from "reactstrap";
import { Delete, Edit } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [courseAssignments, setcourseAssignments] = useState([]);
    const [updateData, setUpdateData] = useState(false)
    const [files, setFiles] = useState([]);

    const [courseModuleId, setCourseModuleId] = useState(0);
    const [modules, setModules] = useState([]);

    const [courseAssignmentId, setCourseAssignmentId] = useState(-1);
    const [courseAssignmentIdx, setCourseAssignmentIdx] = useState(-1);
    const [courseAssignmentName, setCourseAssignmentName] = useState('');
    const [courseAssignmentPoint, setCourseAssignmentPoint] = useState('');
    const [courseAssignmentDescription, setCourseAssignmentDescription] = useState('');
    const [courseAssignmentStartDate, setCourseAssignmentStartDate] = useState(null);
    const [courseAssignmentEndDate, setCourseAssignmentEndDate] = useState(null);

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (!props.courseId) {
            toast.error(`Course is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            var updateData = courseAssignments;
            updateData.forEach(element => {
                element.courseId = props.courseId;
                if (element.courseAssignmentIsActive) {
                    element.courseAssignmentIsActive = element.courseAssignmentIsActive == 1 ? true : false;
                }
            });

            const jsonData = {
                assignments: updateData
            }

            await Axios.post("/courseAssignment/bulk", jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success('Course Modules Created/Updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });

                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })

        }
        window.hLoader();
    }
    const addcourseAssignment = () => {
        if (courseAssignmentIdx > -1) {
            courseAssignments[courseAssignmentIdx].id = courseAssignmentId;
            courseAssignments[courseAssignmentIdx].courseAssignmentName = courseAssignmentName;
            courseAssignments[courseAssignmentIdx].courseModuleId = courseModuleId;
            courseAssignments[courseAssignmentIdx].courseAssignmentPoint = courseAssignmentPoint;
            courseAssignments[courseAssignmentIdx].courseAssignmentDescription = courseAssignmentDescription;
            courseAssignments[courseAssignmentIdx].courseAssignmentStartDate = new Date(courseAssignmentStartDate).toLocaleString();
            courseAssignments[courseAssignmentIdx].courseAssignmentEndDate = new Date(courseAssignmentEndDate).toLocaleString();
        }
        else {
            courseAssignments.push({
                courseAssignmentName: courseAssignmentName,
                courseAssignmentPoint: courseAssignmentPoint,
                courseModuleId : courseModuleId,
                courseAssignmentDescription: courseAssignmentDescription,
                courseAssignmentStartDate: courseAssignmentStartDate,
                courseAssignmentEndDate: courseAssignmentEndDate
            });
        }
        setcourseAssignments(courseAssignments);
        cancelcourseAssignmentEdit();
    }

    const cancelcourseAssignmentEdit = () => {
        setCourseAssignmentIdx(-1);
        setCourseAssignmentId(-1);
        setCourseAssignmentName('');
        setCourseModuleId(0);
        setCourseAssignmentPoint('')
        setCourseAssignmentDescription('')
        setCourseAssignmentStartDate(null);
        setCourseAssignmentEndDate(null);
    }

    const editcourseAssignment = (id, idx) => {
        setCourseAssignmentId(id);
        setCourseAssignmentIdx(idx);
        setCourseModuleId(courseAssignments[idx].courseModuleId);
        setCourseAssignmentName(courseAssignments[idx].courseAssignmentName);
        setCourseAssignmentPoint(courseAssignments[idx].courseAssignmentPoint);
        setCourseAssignmentDescription(courseAssignments[idx].courseAssignmentDescription);
        setCourseAssignmentStartDate(new Date(courseAssignments[idx].courseAssignmentStartDate));
        setCourseAssignmentEndDate(courseAssignments[idx].courseAssignmentEndDate);
    }

    const deletecourseAssignment = (id, idx) => {

    }

    useEffect(async () => {
        if (props.courseId) {
            await Axios.get("/courseModule/dropdown")
                .then((response) => {
                    if (response.data.status==200) {
                        setModules(response.data.data);
                    }
                    else {
                        setModules([])
                    }
                });

            await Axios.get("/courseAssignment/list")
                .then((response) => {
                    if (response.data.recordsTotal) {
                        setcourseAssignments(response.data.data);
                    }
                    else {

                        setcourseAssignments([])
                    }
                });

        }
    }, [props.courseId])

    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardTitle style={{ fontSize: "16px", textAlign: "center", textDecoration: "underline" }}>Assignments</CardTitle>
                        <CardBody style={{ paddingTop: "0.5rem" }}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                            {SHelper.userHasEditPermission() &&
                            <>
                                <Col md="12">
                                    <Row>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Name</Label>
                                                <AvField
                                                    name="courseAssignmentName"
                                                    value={courseAssignmentName}
                                                    onChange={(e) => { setCourseAssignmentName(e.target.value); }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Module</Label>
                                                <Select
                                                    options={modules}
                                                    value={modules ? modules.filter(x => x.value == courseModuleId)[0] : null}
                                                    onChange={(e) => { setCourseModuleId(e.value); }}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Point</Label>
                                                <AvField
                                                    name="courseTypeSequence"
                                                    value={courseAssignmentPoint}
                                                    onChange={(e) => { setCourseAssignmentPoint(e.target.value); }}
                                                    type="number"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Start Datetime</Label>
                                                <Flatpickr
                                                    name={"courseAssignmentStartDate"}
                                                    className="form-control d-block"
                                                    // placeholder="dd M, yyyy"
                                                    value={courseAssignmentStartDate}
                                                    options={{
                                                        dateFormat: 'Y-m-d h:i K',
                                                        timeFormat: 'h:i K',
                                                        enableTime: true,
                                                        time_24hr: false,
                                                        // altInput: true,
                                                        // altFormat: 'F j, Y h:i K',
                                                        // altInputClass: 'flatpickr-alt-input',
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setCourseAssignmentStartDate(dateStr);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>End Datetime</Label>
                                                <Flatpickr
                                                    name={"courseAssignmentEndDate"}
                                                    className="form-control d-block"
                                                    // placeholder="dd M, yyyy"
                                                    value = {courseAssignmentEndDate}
                                                    options={{
                                                        dateFormat: 'Y-m-d h:i K',
                                                        timeFormat: 'h:i K',
                                                        enableTime: true,
                                                        time_24hr: false,
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setCourseAssignmentEndDate(dateStr);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Description</Label>
                                                <AvField
                                                    name="courseTypeDescription"
                                                    value={courseAssignmentDescription}
                                                    onChange={(e) => { setCourseAssignmentDescription(e.target.value); }}
                                                    type="textarea"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <div className="actions clearfix">
                                    <Button
                                        style={{ float: "right", marginLeft: "10px" }}
                                        type="button"
                                        color="primary"
                                        onClick={addcourseAssignment}
                                    >{courseAssignmentIdx > -1 ? "Update" : "Add"}</Button>
                                    {courseAssignmentIdx > -1 &&
                                        <Button
                                            style={{ float: "right" }}
                                            type="button"
                                            color="danger"
                                            onClick={cancelcourseAssignmentEdit}
                                        >Cancel</Button>}
                                </div>
                                </>}
                                <CardTitle typeof="h4" >Assignments List</CardTitle>
                                <Col md={12}>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Points</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Description</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {courseAssignments.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{item.courseAssignmentName}</td>
                                                            <td>{item.courseAssignmentPoint}</td>
                                                            <td>{new Date(item.courseAssignmentStartDate).toLocaleString()}</td>
                                                            <td>{new Date(item.courseAssignmentEndDate).toLocaleString()}</td>
                                                            <td>{item.courseAssignmentDescription.length > 20 ? `${item.courseAssignmentDescription.substring(0, 20)} ...` : item.courseAssignmentDescription}</td>
                                                            <td>
                                                                <Box sx={{ display: courseAssignmentIdx === i ? 'none' : 'flex' }}>
                                                                    {SHelper.userHasEditPermission() && <>
                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                            <IconButton onClick={() => { editcourseAssignment(item.id, i) }}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                            <IconButton style={{ color: "red" }} onClick={() => { deletecourseAssignment(item.id, i) }}>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        </>}
                                                                </Box>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                {courseAssignments.length > 0 &&
                                    <div className="actions clearfix">
                                        <Button
                                            style={{ float: "right", marginLeft: "10px" }}
                                            type="submit"
                                            color="primary"
                                        >Update</Button>
                                    </div>}
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
