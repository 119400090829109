import React from "react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MainModel from "./model"

const FormValidations = () => {

  return (
    <>
      <div className="page-content">
          <Breadcrumbs title="Transaction" breadcrumbItem="New Course" />
          <MainModel/>
      </div>
    </>
  )
}

export default FormValidations
