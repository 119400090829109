import React, { useState, useEffect } from "react"

import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Row, Col, Input, Label, Card, CardTitle,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Axios from "../../helpers/axios_helper";
import queryString from 'query-string';

import SideBar from "./sidebar";
import Syllabus from "./syllabus";
import Module from "./module";
import Assignment from "./assignment";
import Participant from "./participant";
import Instructor from "./instructor";
import Class from "./class";
import File from "./file";
import Attendance from "./attendance";

const Course = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const [courseId, setCourseId] = useState(0);
  const [courseName, setCourseName] = useState('');
  const [pageName, setPageName] = useState();
  const [courseList, setCourseList] = useState([]);

  useEffect(async () => {
    if (queryParams.page) {
      setPageName(queryParams.page);
      if (courseList.length == 0) {
        window.sLoader();
        await Axios.get("/course/dropdown")
          .then((response) => {
            if (response.data.status === 200) {
              setCourseList(response.data.data);
              if(queryParams.courseId){
                setCourseId(queryParams.courseId);
                const course = response.data.data.filter(x=>x.value==queryParams.courseId)[0]
                if(course){
                  setCourseName(course.label)
                }
                else{
                  history.push('/course-list')
                }
              }
            }
            else {
              setCourseList([])
            }
          });
        window.hLoader();
      }
    }
  }, [queryParams.page])

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Course" breadcrumbItem="Details" />

        <Row>
          <Col xs="12">
            <SideBar pageName={pageName} />

            <div className="email-rightbar mb-3">
              <Card>
                
                  {courseId == 0 && 
                  <div className="btn-toolbar p-4">
                    <Col md={3}>
                      <span
                        className="btn btn-primary waves-light"
                      >Select a Course
                      </span>
                    </Col>
                    <Col md={9}>
                      <Select
                        options={courseList}
                        value={courseList ? courseList.filter(x => x.value == courseId)[0] : null}
                        onChange={(e) => { setCourseId(e.value); setCourseName(e.label) }}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    </div>}
                  {courseId > 0 &&
                    <Col md={12} className="pt-2">
                      <CardTitle style={{ fontSize: "20px", textAlign: "center" }}>{courseName}</CardTitle>
                    </Col>
                  }
                {/* <hr style={{margin:"0"}}/> */}
                {pageName == 'modules' &&
                  <Module courseId={courseId} />
                }
                {pageName == 'syllabus' &&
                  <Syllabus courseId={courseId} />
                }
                {pageName == 'assignments' &&
                  <Assignment courseId={courseId} />
                }
                {pageName == 'participants' &&
                  <Participant courseId={courseId} />
                }
                {pageName == 'instructors' &&
                  <Instructor courseId={courseId} />
                }
                {pageName == 'classes' &&
                  <Class courseId={courseId} />
                }
                {pageName == 'files' &&
                  <File courseId={courseId} />
                }
                {pageName == 'attendances' &&
                  <Attendance courseId={courseId} />
                }
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Course